.MuiDialog-root {
  z-index: 900 !important;
}

.MuiDialog-container {
  height: 100%;
  outline: 0;
  margin-top: 1% !important;
}
.g-recaptcha {
  width: auto !important;
  height: auto !important;
  text-align: -webkit-center;
  text-align: -moz-center;
  text-align: -o-center;
  text-align: -ms-center;
}
.g-recaptcha > div > div {
  text-align: left;
  width: auto !important;
  height: auto !important;
}
.MuiDialog-paperWidthSm {
  margin-top: 2%;
  border-radius: 10px !important;
  background-color: #ffffff !important;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05) !important;
  padding: 0% 2.5% 2.96% 3.13%;
  max-height: 97% !important;
}

.fill-in-the-contact {
  color: #858e9c;
  font-family: "DM Sans";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -0%;
  margin-bottom: 6%;
}
.keep-an-eye {
  color: #858e9c;
  font-family: "DM Sans";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -0%;
}
.input-container {
  label {
    color: #444c66;
    font-family: "DM Sans";
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 2.5%;
  }
  input {
    margin-top: 2%;
    width: 93.81%;
    padding: 2.78%;
    border: 1px solid #d0d5de;
    border-radius: 5px;
    background-color: #ffffff;
    color: #828796;
    font-family: "DM Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 21px;
  }
  .input-error {
    color: red;
    display: block;
    font-size: 12px;
    font-family: "DM Sans";
    height: 21px;
  }
  .no-error {
    opacity: 0;
    margin-top: -2%;
    height: 18px;
    font-size: 14px;
    font-family: "DM Sans";
  }
  textarea:focus,
  input:focus {
    outline: 0;
  }
  textarea {
    margin-top: 2%;
    width: 93.81%;
    padding: 2.78%;
    border: 1px solid #d0d5de;
    border-radius: 5px;
    background-color: #ffffff;
    color: #828796;
    font-family: "DM Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 21px;
  }
  .search-location-input {
    margin-top: 2%;
    position: relative;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    height: 50px;
    border: 1px solid rgba(136, 139, 149, 0.5);
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
  }

  .search-location-input input {
    margin-top: 0%;
    border: none;
    width: 100%;
    background-color: transparent;
    color: #444c66;
    font-family: "Dm Sans";
    font-size: 16px;
    padding-left: 8%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .search-location-input input:focus {
    outline: none;
  }
  .loc-img {
    position: absolute;
    top: 28%;
    left: 2%;
    height: 22px;
    width: 17px;
  }
}
.MuiTypography-h6 {
  color: #221f1f !important;
  font-family: "DM Sans" !important;
  font-size: 32px !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 42px !important;
  padding: 0 !important;
}
.MuiDialogTitle-root {
  flex: 0 0 auto;
  margin: 0;
  padding: 30px 0px 10px !important;
}
.check-icon .modal-title {
  margin-top: 10%;
}

.modal-submit {
  padding: 2.78%;
  width: 99%;
  border-radius: 5px;
  background-color: #2c91ea;
  box-shadow: 3px 10px 28px 0 rgba(74, 104, 130, 0.4);
  color: #ffffff;
  font-family: "DM Sans";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
}
.modal-submit:focus {
  outline: none;
}
.modal-submit:hover {
  background-color: #3abaff;
}

.modal-submit2 {
  margin-top: 8%;
  padding: 2.78%;
  width: 99%;
  border-radius: 5px;
  background-color: #2c91ea;
  box-shadow: 3px 10px 28px 0 rgba(74, 104, 130, 0.4);
  color: #ffffff;
  font-family: "DM Sans";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
  margin-bottom: 30px;
}
.modal-submit2:focus {
  outline: none;
}
.modal-submit2:hover {
  background-color: #3abaff;
}

.check-icon {
  width: 1.8em !important;
  height: 1.8em !important;
  fill: #828796 !important;
  margin-left: -1.5% !important;
  margin-bottom: -4% !important;
  font-size: 2.1875rem !important;
  border: 0px !important;
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (min-width: 576px) {
  .MuiDialog-paperWidthSm {
    max-width: 440px !important;
    width: 440px !important;
    padding: 0% 5.5% 2.96% 5.13% !important;
  }
}

@media screen and (max-width: 767px) {
  .MuiTypography-h6 {
    font-size: 28px !important;
    line-height: 32px !important;
  }
  .fill-in-the-contact {
    font-size: 14px;
    line-height: 20px;
  }
  .input-container {
    margin-top: -15px;
  }
  .input-container label {
    font-size: 18px;
  }
  .input-container input {
    margin-top: 2%;
    padding: 2.78%;
    margin-bottom: 2%;
  }

  .input-container .search-location-input input {
    margin-top: 2%;
    font-size: 14px;
    padding-left: 8%;
  }
  .keep-an-eye {
    font-size: 14px;
  }
  .MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    margin-top: 7%;
    padding: 32px 0px 16px 0px !important;
  }

  .MuiDialog-paper {
    margin: 15px !important;
  }
  #g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (max-width: 575px) {
  .modal-submit2 {
    margin-bottom: 20px;
  }
  .MuiDialog-paperWidthSm {
    max-width: 100% !important;
    width: 270px !important;
    padding: 0% 4.5% 5.96% 4.13% !important;
  }
  .MuiTypography-h6 {
    font-size: 20px !important;
    line-height: 26px !important;
  }
  .fill-in-the-contact {
    font-size: 12px;
    line-height: 18px;
  }
  .input-container {
    margin-top: -15px;
  }
  .input-container label {
    font-size: 14px;
  }
  .input-container input {
    margin-top: 2%;
    padding: 2.78%;
    margin-bottom: 2%;
    font-size: 12px;
  }
  .input-container .loc-img {
    position: absolute;
    top: 24%;
    left: 2%;
    height: 16px;
    width: 13px;
  }
  .input-container .search-location-input {
    height: 35px;
  }

  .input-container .search-location-input input {
    margin-top: 2%;
    font-size: 12px;
    padding-left: 12%;
  }

  .keep-an-eye {
    font-size: 12px;
  }
  .MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    margin-top: 7%;
    padding: 16px 0px !important;
    padding-top: 15% !important;
  }

  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
  .mb-screen {
    margin-top: -7%;
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media screen and (min-width: 768px) {
  .MuiDialog-paperWidthSm {
    max-width: 66.06% !important;
    width: 66.06 !important;
    padding: 0% 2.5% 2.96% 3.13% !important;
  }
  .MuiSvgIcon-fontSizeLarge {
    font-size: 2.875rem;
  }
  .fill-in-the-contact {
    color: #858e9c;
    font-family: "DM Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: 0%;
    margin-bottom: 6%;
  }
}

/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .MuiDialog-paperWidthSm {
    max-width: 440px !important;
    width: 440px !important;
    padding: 0% 2.5% 2.96% 3.13% !important;
  }
  .MuiSvgIcon-fontSizeLarge {
    font-size: 2.875rem;
  }
}

@media only screen and (min-width: 1200px) {
  .MuiDialog-paperWidthSm {
    max-width: 440px !important;
    width: 440px !important;
    padding: 0% 2.5% 2.96% 3.13% !important;
  }
  .MuiSvgIcon-fontSizeLarge {
    font-size: 2.875rem;
  }

  .sarc-btn{
    width:180px;
  }
}
