.result-container {
  position: relative;
  margin-top: 2.91%;
  padding-bottom: 50px;
  .here-are-your-top-ev {
    margin-left: 6.91%;
    p {
      height: 42px;
      width: max-content;
      color: #221f1f;
      font-family: "DM Sans";
      font-size: 32px;
      letter-spacing: 0;
      line-height: 42px;
      text-align: left;
    }
  }
}
.fa-question-circle:before {
  color: #ffff;
}
.card-container {
  margin: 0px 6.91% 30px 6.91%;
  position: relative;
  height: 31.66%;
  border-radius: 10px;

  .upper-container {
    position: relative;
    width: 100%;
    height: 85%;
    background-color: lightpink;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    .cr-img {
      position: relative;
      width: 30%;
      height: 100%;
      margin: 2.32% 1.25%;
      display: flex;
      flex-direction: column;
      .classify-vl {
        position: absolute;

        height: 20px;
        padding: 1% 7% 1% 7%;
        border-radius: 5px 0 5px 0;
        background-color: rgba(71, 79, 96, 0.7);
        color: #ffffff;
        font-family: "DM Sans";
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
        img {
          width: 23px;
          height: 12px;
        }
      }
      a {
        width: 95.75%;
        height: 143px;
      }
      a:focus {
        outline: none;
        opacity: 0.7;
      }
      img {
        width: 100%;
        height: 145px;
        border-radius: 5px;
      }
      button {
        margin-top: 3.9%;
        height: 50px;
        width: 95.75%;
        border-radius: 5px;
        border: none;
        background-color: #2c91ea;
        color: #ffffff;
        font-family: "DM Sans";
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        outline: none;
        cursor: pointer;
      }
      button:hover {
        background-color: #3abaff;
      }
      button:focus {
        background-color: #3abaff;
      }
      .drive-inactive{
        background-color: #D0D5DE!important;
      }
    }
    .cr-img-02 {
      position: relative;
      width: 70%;
      display: flex;
      flex-direction: column;
      .cr-img-header {
        height: 20%;
        a {
          text-decoration: none;
        }
        a:focus {
          outline: none;
          text-decoration: underline;
        }
        a:hover {
          text-decoration: underline;
        }
        p {
          color: #444c66;
          font-family: "DM Sans";
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 26px;
          text-align: left;
        }
      }
      .cr-details {
        display: flex;
        flex-direction: row;
        height: 100%;

        .col-mdl-01 {
          width: 70%;
          height: 92%;
          table {
            width: 100%;
            height: 100%;
            tr {
              text-align: left;
            }
            th,
            td {
              width: 50%;
              text-align: left;
            }
            th {
              color: #828796;
              font-family: "DM Sans";
              font-size: 14px;
              letter-spacing: 0;
              line-height: 18px;
              font-weight: 500;
            }
            td {
              vertical-align: text-top;
              color: #444c66;
              font-family: "DM Sans";
              font-size: 18px;
              font-weight: 600;
              letter-spacing: 0;
              line-height: 24px;
            }
            .view-car {
              height: 50px;
              width: 90%;
              border: 1px solid #d0d5de;
              border-radius: 5px;
              background-color: #ffffff;
              color: #2c91ea;
              font-family: "DM Sans";
              font-size: 16px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 21px;
              text-align: center;
              cursor: pointer;
            }
            .blue-car:hover {
              border: 1px solid #2c91ea;
            }
            .reser-open {
              color: #2ec1ac !important;
            }
            .reser-open:hover {
              color: #2ec1ac !important;
              border: 1px solid #2ec1ac !important;
            }
            .com-soon {
              color: #ee9022 !important;
            }
            .com-soon:hover {
              border: 1px solid #ee9022 !important;
              color: #ee9022 !important;
            }
            .alloc-sold {
              color: #c65258 !important;
            }
            .alloc-sold:hover {
              border: 1px solid #c65258 !important;
              color: #c65258 !important;
            }
            .square-btn {
              vertical-align: middle;
              cursor: pointer;
              box-sizing: border-box;
              height: 50px;
              width: 50px;
              border: 1px solid #d0d5de;
              border-radius: 5px;
              background-color: #ffffff;
              letter-spacing: 0;
              line-height: 21px;
              text-align: center;
              img {
                height: 30px;
                width: 25px;
                vertical-align: middle;
              }
            }
            .square-btn:hover {
              border: 1px solid #474f60;
            }
            button:focus {
              outline: none;
            }
          }
        }
        .col-mdl-02 {
          width: 30%;
          padding-top: 3%;
          background-color: #ffffff;
          border-top-left-radius: 70px;
          z-index: 800;
          box-shadow: -18px -18px 26px -10px rgba(133, 142, 156, 0.07);
        }
      }
    }
  }
}

.MuiSvgIcon-fontSizeInherit {
  font-size: inherit;
  border: none !important;
  height: 30px !important;
  width: 30px !important;
}

.MuiSvgIcon-fontSizeLarge {
  font-size: 2.875rem !important;
  border: none !important;
}

.MuiRating-root {
  color: #474f60 !important;
  margin-top: -4% !important;
}

.MuiRating-sizeLarge {
  font-size: 1.875rem;
}

.group-11 {
  margin: 4.91% 6.91% 20px 6.91%;

  height: 50px;
  display: flex;

  button {
    cursor: pointer;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    box-sizing: border-box;
    font-family: "DM Sans";
    font-size: 18px;
    border-radius: 5px;
    outline: none;
  }
  button:focus {
    outline: none;
  }
}

.start-again {
  width: 22.28%;
  border: 1px solid #d0d5de;
  color: #828796;
  margin-right: 3%;
  padding-left: 2%;
}
.start-again:hover {
  border: 1px solid #474f60;
  color: #474f60;
}
.start-again:focus {
  border: 1px solid #474f60;
  color: #474f60;
}

.bck-btn {
  width: 18.28%;
  border: 1px solid #d0d5de;
  background-color: #ffffff;
  color: #828796;
}
.bck-btn:hover {
  border: 1px solid #474f60;
  color: #474f60;
}
.bck-btn:focus {
  border: 1px solid #474f60;
  color: #474f60;
}
.load-more {
  width: 38%;
  margin-left: 28%;
  float: right;
  background-color: #474f60;
  color: #ffffff;
  font-weight: bold;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.load-more-disabled {
  opacity: 0.2 !important;
  cursor: default !important;
}
.load-more:hover {
  opacity: 0.6;
}

.load-more:focus {
  opacity: 0.6;
}

.share-rectangle {
  height: 136px;
  width: 103%;
  margin-top: 7%;
  background-color: #221f1f;
  border-left: 1px solid #f5f7f9;
  .footer-content {
    padding-top: 0.02%;
    p {
      color: #ffffff;
      font-family: "DM Sans";
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 26px;
    }
  }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (min-width: 576px) {
  .result-container {
    max-width: 580px;
  }
  .card-container {
    margin: 0px 3.91% 30px 3.91%;
    width: 536px;
  }
  .result-container .here-are-your-top-ev {
    margin-left: 3.91%;
  }
  .result-container .here-are-your-top-ev p {
    height: 37px;
    font-size: 24px;
    line-height: 37px;
    margin-bottom: 3%;
  }
  .card-container .upper-container .cr-img-02 .cr-img-header p {
    font-size: 18px;
    line-height: 16px;
  }

  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 table th {
    font-size: 14px;
    line-height: 18px;
  }

  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 table td {
    font-size: 16px;
    line-height: 21px;
  }

  .card-container .upper-container .cr-img button {
    font-size: 16px;
    line-height: 21px;
    height: 40px;
  }

  .card-container
    .upper-container
    .cr-img-02
    .cr-details
    .col-mdl-01
    table
    .view-car {
    height: 40px;
    font-size: 16px;
    line-height: 21px;
  }

  .card-container
    .upper-container
    .cr-img-02
    .cr-details
    .col-mdl-01
    table
    .square-btn {
    height: 40px;
    width: 40px;
  }
  .card-container .upper-container .cr-img {
    width: 60%;
  }

  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 table th,
  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 table td {
    width: 60%;
    text-align: left;
  }

  .card-container .upper-container .cr-img img {
    height: 172px;
  }

  .group-11 {
    margin: 4.91% 3.91% 20px 3.91%;
    height: 40px;
  }

  .group-11 {
    margin: 4.91% 3.91% 20px 3.91%;
    height: 40px;
    a {
      width: 32.28%;
    }
    .bck-btn {
      width: 32.28%;
      height: 40px;
    }
  }

  .group-11 button {
    line-height: 21px;
    font-size: 14px;
  }

  .start-again {
    width: 32.28%;
  }
}

@media screen and (max-width: 767px) {
  .load-more {
    width: 46%;
    margin-left: 15%;
  }
  .card-container .upper-container {
    box-shadow: none;
    margin-top: -3%;
  }
  .card-container .upper-container .cr-img {
    margin: 2.32% 3.25%;
    display: flex;
    flex-direction: column;
  }
  .card-container .upper-container .cr-img-02 {
    position: relative;
    width: 40%;
    display: flex;
    flex-direction: column;
  }
  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-02 {
    width: 80%;
    padding-top: 12%;
    padding-left: 6%;
    background-color: #ffffff;
    border-top-left-radius: 70px;
    z-index: 800;
    box-shadow: -18px -18px 26px -10px rgba(133, 142, 156, 0.07);
  }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (max-width: 575px) {
  .card-container {
    margin: 0px 3.91% 30px 3.91%;
    max-width: 322px;
  }
  .result-container {
    max-width: 352px;
  }
  .result-container .here-are-your-top-ev {
    margin-left: 3.91%;
  }
  .result-container .here-are-your-top-ev p {
    height: 24px;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 3%;
  }

  .card-container .upper-container .cr-img-02 {
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  .card-container .upper-container .cr-img-02 .cr-img-header p {
    font-size: 18px;
    line-height: 16px;
  }

  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 table th {
    font-size: 14px;
    line-height: 18px;
  }

  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 table td {
    font-size: 16px;
    line-height: 21px;
  }

  .card-container .upper-container .cr-img button {
    font-size: 16px;
    line-height: 21px;
    height: 40px;
  }

  .card-container
    .upper-container
    .cr-img-02
    .cr-details
    .col-mdl-01
    table
    .view-car {
    height: 40px;
    font-size: 16px;
    line-height: 21px;
  }

  .card-container
    .upper-container
    .cr-img-02
    .cr-details
    .col-mdl-01
    table
    .square-btn {
    height: 40px;
    width: 40px;
  }
  .card-container .upper-container .cr-img {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .card-container .upper-container .cr-img {
    margin: 2.32% 3.25% 1% 3.25%;
  }

  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 table th,
  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 table td {
    width: 60%;
    text-align: left;
  }

  .card-container .upper-container .cr-img img {
    height: 161px;
  }

  .group-11 {
    margin: 30.91% 3.91% 20px 3.91%;
    height: 35px;
    flex-wrap: wrap-reverse;
    a {
      width: 48.28%;
    }
    .bck-btn {
      width: 48.28%;
      height: 35px;
    }
  }

  .group-11 button {
    line-height: 21px;
    font-size: 14px;
  }

  .start-again {
    width: 48.28%;
    
    height: 35px;
  }

  .sarc-btn{
    width:180px
  }

  .load-more {
    width: 100%;
    margin-left: 0%;
    height: 35px;
    margin-bottom: 15px;
  }
  .share-rectangle {
    display: none;
  }
  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-02 {
    width: 100%;
    padding-top: 22%;
    padding-left: 6%;
    background-color: #ffffff;
    border-top-left-radius: 70px;
    z-index: 800;
    box-shadow: -18px -18px 26px -10px rgba(133, 142, 156, 0.07);
  }
}
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media screen and (min-width: 768px) {
  .result-container {
    max-width: 770px;
  }
  .card-container {
    margin: 0px 3.91% 30px 3.91%;
    width: 708px;
  }
  .result-container .here-are-your-top-ev {
    margin-left: 3.91%;
  }
  .result-container .here-are-your-top-ev p {
    height: 37px;
    font-size: 28px;
    line-height: 37px;
    margin-bottom: 3%;
  }
  .card-container .upper-container .cr-img-02 .cr-img-header p {
    font-size: 18px;
    line-height: 16px;
  }

  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 table th {
    font-size: 14px;
    line-height: 18px;
  }

  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 table td {
    font-size: 16px;
    line-height: 21px;
  }

  .card-container .upper-container .cr-img button {
    font-size: 16px;
    line-height: 21px;
    height: 40px;
  }

  .card-container
    .upper-container
    .cr-img-02
    .cr-details
    .col-mdl-01
    table
    .view-car {
    height: 40px;
    font-size: 16px;
    line-height: 21px;
  }

  .card-container
    .upper-container
    .cr-img-02
    .cr-details
    .col-mdl-01
    table
    .square-btn {
    height: 40px;
    width: 40px;
  }
  .card-container
    .upper-container
    .cr-img-02
    .cr-details
    .col-mdl-01
    table
    .square-btn
    img {
    height: 25px;
    width: 20px;
    vertical-align: middle;
  }
  .card-container .upper-container .cr-img {
    width: 34%;
  }
  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 {
    width: 70%;
    height: 97%;
  }

  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 table th,
  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 table td {
    width: 60%;
    text-align: left;
  }

  .card-container .upper-container .cr-img img {
    height: 130px;
  }

  .group-11 {
    margin: 4.91% 3.91% 20px 3.91%;
    height: 40px;
    a {
      width: 150px;
    }
    .bck-btn {
      width: 150px;
      height: 40px;
    }
  }

  .group-11 button {
    line-height: 21px;
    font-size: 16px;
  }

  .start-again {
    width: 150px;
  }
}

/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .card-container
    .upper-container
    .cr-img-02
    .cr-details
    .col-mdl-01
    table
    .square-btn
    img {
    height: 30px;
    width: 25px;
    vertical-align: middle;
  }
  .result-container {
    max-width: 1000px;
  }
  .card-container {
    margin: 0px 3.91% 30px 3.91%;
    width: 915px;
  }
  .here-are-your-top-ev {
    margin-left: 3.91%;
  }
  .card-container .upper-container .cr-img-02 .cr-img-header p {
    font-size: 20px;
    line-height: 26px;
  }

  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 table th {
    font-size: 14px;
    line-height: 18px;
  }

  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 table td {
    font-size: 18px;
    line-height: 24px;
  }

  .card-container .upper-container .cr-img button {
    font-size: 18px;
    line-height: 24px;
    height: 50px;
  }

  .card-container
    .upper-container
    .cr-img-02
    .cr-details
    .col-mdl-01
    table
    .view-car {
    height: 50px;
    font-size: 16px;
    line-height: 21px;
  }

  .card-container
    .upper-container
    .cr-img-02
    .cr-details
    .col-mdl-01
    table
    .square-btn {
    height: 50px;
    width: 50px;
  }
  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 {
    width: 70%;
    height: 88%;
  }
  .card-container .upper-container .cr-img {
    width: 30%;
  }
  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 table th,
  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 table td {
    width: 50%;
    text-align: left;
  }

  .card-container .upper-container .cr-img img {
    height: 145px;
  }

  .group-11 {
    margin: 4.91% 3.91% 20px 3.91%;
    height: 50px;
    a {
      width: 170px;
    }
    .bck-btn {
      width: 170px;
      height: 50px;
    }
  }

  .group-11 button {
    line-height: 24px;
    font-size: 18px;
  }

  .start-again {
    width: 170px;
  }
  .load-more {
    width: 320px;
    margin-left: 25%;
  }
}

@media only screen and (min-width: 1200px) {
  .card-container
    .upper-container
    .cr-img-02
    .cr-details
    .col-mdl-01
    table
    .square-btn
    img {
    height: 30px;
    width: 25px;
    vertical-align: middle;
  }
  .result-container {
    max-width: 860px;
  }
  .result-container .here-are-your-top-ev {
    margin-left: 6.91%;
  }
  .result-container .here-are-your-top-ev p {
    height: 42px;
    font-size: 32px;
    line-height: 42px;
  }
  .result-container .here-are-your-top-ev p {
    height: 42px;
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 3%;
  }

  .card-container {
    margin: 0px 6.91% 30px 6.91%;
    width: 750px;
  }
  .card-container .upper-container .cr-img-02 .cr-img-header p {
    font-size: 20px;
    line-height: 26px;
  }

  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 table th {
    font-size: 14px;
    line-height: 18px;
  }
  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 table td {
    font-size: 18px;
    line-height: 24px;
  }

  .card-container .upper-container .cr-img button {
    font-size: 18px;
    line-height: 24px;
    height: 50px;
  }

  .card-container
    .upper-container
    .cr-img-02
    .cr-details
    .col-mdl-01
    table
    .view-car {
    height: 50px;
    font-size: 16px;
    line-height: 21px;
  }
  .card-container
    .upper-container
    .cr-img-02
    .cr-details
    .col-mdl-01
    table
    .square-btn {
    height: 50px;
    width: 50px;
  }

  .card-container .upper-container .cr-img {
    width: 34%;
  }
  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 {
    width: 70%;
    height: 95%;
  }
  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 table th,
  .card-container .upper-container .cr-img-02 .cr-details .col-mdl-01 table td {
    width: 50%;
    text-align: left;
  }

  .card-container .upper-container .cr-img img {
    height: 143px;
  }

  .group-11 {
    margin: 4.91% 6.91% 20px 6.91%;
    height: 50px;
    
    .bck-btn {
      width: 170px;
      height: 50px;
    }
  }
  .group-11 button {
    line-height: 24px;
    font-size: 18px;
  }

  .start-again {
    width: 170px;
  }
  .load-more {
    width: 320px;
    margin-left: 21%;
  }
}
