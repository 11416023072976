body {
  margin: 0;
  padding: 0;
  font-family: "DM Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  overflow: auto;
  background-color: #f5f7f9;
}
