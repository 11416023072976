.bar1,
.bar2,
.bar3 {
  width: 26px;
  height: 3px;
  background-color: #ffffff;
  margin: 6px 0;
  border-radius: 3px;
  transition: 0.2s;
}
.container {
  display: block;
  width: 26px;
  opacity: 0.8;
  transition: 0.2s;
}
.sarc-btn{
  width:180px
}

.container:hover {
  opacity: 1;
}
.close {
  width: 30px;
  border: 2px solid #ffff;
  border-radius: 50%;
  padding-left: 8%;
}

.close .bar1 {
  -webkit-transform: translate(0px, 8px) rotate(-45deg);
  transform: translate(0px, 8px) rotate(-45deg);
}

.close .bar2 {
  opacity: 0;
}

.close .bar3 {
  -webkit-transform: translate(0, -10px) rotate(45deg);
  transform: translate(0, -10px) rotate(45deg);
}

#menu {

}

/*========= side navigation ========= */
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 810;
  top: 0;
  left: 0;
  opacity: 1;
  background-color: #221f1f;
  overflow-x: hidden;
  padding-top: 8%;
  transition: 0.5s;
}

/* The navigation menu links */

.sidenav a:hover {
  color: #f1f1f1;
}

.open .sidenav {
  width: 27.03%;
}

.menubutton {
  position: absolute;
  top: 20px;
  left: 30px;
  z-index: 1102;
  cursor: pointer;
}

@media only screen and (max-width: 575px) {
  .open .sidenav {
    width: 270px;
  }
  .menubutton {
    top: 13px;
    left: 15px;
  }

  .open .menubutton {
    position: fixed;
  }
}

@media only screen and (min-width: 576px) {
  .open .sidenav {
    width: 54.03%;
  }

  .open .menubutton {
    position: fixed;
  }
}

@media only screen and (min-width: 768px) {
  .open .sidenav {
    width: 41.03%;
  }

  .open .menubutton {
    position: fixed;
  }
}

@media only screen and (min-width: 992px) {
  .open .sidenav {
    width: 33%;
  }

  .open {
    .menubutton {
      position: fixed;
    }
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .menubutton {
    display: none;
  }

  .sidenav {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    opacity: 1;
    background-color: #221f1f;
    overflow-x: hidden;
    padding-top: 5%;
    transition: 0.5s;
  }
}
