.search-home {
  .search-location-input {
    position: relative;
    display: flex;
    box-sizing: border-box;
    height: 50px;
    width: 48.44%;
    border: 1px solid rgba(136, 139, 149, 0.5);
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
    margin-left: 2.69%;
  }

  .search-location-input:focus-within {
    border: 1px solid #c3cfe2;
    border-radius: 10px 0 0 10px;
    border-color: rgba(223, 225, 229, 0);
    border-color: rgba(223, 225, 229, 0);
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  }

  .search-location-input:hover {
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
    border-radius: 5px;
    border: 1px solid #c3cfe2;
    border-color: rgba(223, 225, 229, 0);
  }

  .search-location-input input {
    border: none;
    width: 100%;
    background-color: transparent;
    color: #444c66;
    font-family: "Dm Sans";
    font-size: 16px;
    padding-left: 8%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .search-location-input input:focus {
    outline: none;
  }
  .loc-img {
    position: absolute;
    top: 28%;
    left: 2%;
    height: 22px;
    width: 17px;
  }
}

//input size for less than 768 px
@media only screen and (max-width: 768px) {
  .search-home .search-location-input input {
    font-size: 14px;
    padding-left: 10%;
  }
  .sarc-btn{
    width:180px;
  }
}


//for mobile screen
@media only screen and (max-width: 575px) {
  .search-home .search-location-input input {
    font-size: 12px !important;
    padding-left: 10%;
  }

  .search-home .loc-img {
    height: 20px;
    width: 14px;
  }
  @media not all and (min-resolution:.001dpcm)
  { @supports (-webkit-appearance:none) {
  
    
    .search-home .search-location-input input {
      font-size: 16px !important;
      
    }
    .search-home .search-location-input input:focus {
      font-size: 16px !important;
      
    }
  }
}
}

