.hm-header {
  margin: 0%;
  padding: 0%;
  position: relative;

  .return-trip {
    margin-top: 3%;
    color: #384865;
    font-family: "DM Sans";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    span {
      color: #2c91ea;
    }
  }

  h4 {
    position: relative;
    margin-top: -1%;
    height: 49px;
    color: #444c66;
    font-family: "DM Sans";
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 49px;
    text-align: left;
    padding-left: 2.91%;
  }
  .in-case-you-dont-ke {
    margin-top: -3.91%;
    color: #474f60;
    font-family: "DM Sans";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
  }
  .com-sch {
    margin-top: -4.91%;
    color: #858e9c;
    font-family: "DM Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 28px;
  }
  p {
    padding-left: 2.91%;
    height: 5.47%;
    width: 67.36%;
    color: #444c66;
    font-family: "DM Sans";
    font-size: 20px;
    letter-spacing: 0;
    line-height: 28px;
    text-align: left;
  }
  .you-have-somewhere-w {
    margin-top: 7.91%;
    color: #474f60;
    font-family: "DM Sans";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px;
  }
  .would-you-consider-a {
    position: relative;
    padding-top: 0.88%;
    height: 31px;
    color: #474f60;
    font-family: "DM Sans";
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 31px;
  }
  .plug-in-hybrids-phe {
    height: 63px;
    color: #828796;
    font-family: "DM Sans";
    font-size: 16px;
    width: 90%;
    letter-spacing: 0;
    line-height: 21px;
  }
  .by-clicking-find-my {
    position: relative;
    height: 18px;
    color: #474f60;
    font-family: "DM Sans";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    padding-top: 4.91%;
    padding-bottom: 16.6%;

    a {
      color: #474f60;
      font-family: "DM Sans";
      cursor: pointer;
    }
    a:focus {
      outline: none;
      opacity: 0.6;
    }
  }
}
.search-home {
  margin-top: 4.91%;
}

.week-slider {
  width: 40.17%;
  margin-left: 2.91%;
  display: flex;
}
.slid-val {
  margin-left: 3.91%;
  box-sizing: border-box;
  height: 50px;
  width: 70px;
  padding: 14px 11.5px 14px 11.5px;
  background-color: #ffffff;
  color: #2c91ea;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}
.week-droop {
  display: flex;
  justify-content: left;
  margin-top: 2%;
  padding-left: 1.91%;

  p {
    margin-top: 2.5%;
    color: #384865;
    font-family: "DM Sans";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
  }
}
.btn-col-02 {
  margin-top: -4.91% !important;
}
.inputbox-error {
  color: red;
  display: block;
  font-size: 16px;
  font-family: "DM Sans";
  text-align: left;
  margin-left: 2.91%;
}
.no-error {
  opacity: 0;
  margin-top: -2%;
}
.rectangle-button {
  margin-top: 4.91%;
  box-sizing: border-box;
  height: 50px;
  width: 29.89%;
  border: 1px solid #d0d5de;
  border-radius: 5px 5px 5px 5px;
  background-color: #ffffff;
  display: flex;
  margin-left: 2.91%;

  button {
    height: 48px;
    width: 50%;
    color: #444c66;
    font-family: "DM Sans";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    cursor: pointer;
  }

  .button-inactive {
    background-color: #ffffff;
    border: none;
    border-radius: 5px 0 0 5px;
  }
  .button-right-inactive {
    background-color: #ffffff;
    border: none;
    border-radius: 0px 5px 5px 0px;
  }
  button:focus {
    outline: none;
    opacity: 0.6;
  }
  .button-active {
    margin-left: 0%;
    border: 1px solid #474f60;
    border-radius: 5px 0 0 5px;
    background-color: #474f60;
    color: #ffffff;
  }
  .button-right-active {
    margin-left: 0%;
    border: 1px solid #474f60;
    border-radius: 0px 5px 5px 0px;
    background-color: #474f60;
    color: #ffffff;
  }
}
.image-container {
  width: 100%;
  display: flex;
  margin-left: 3.13%;
  margin-top: -2.5%;
  .car-img-section {
    width: 14.45%;
    margin-right: 1.93%;

    label {
      height: 120px;
      border: 1px solid #fff;
      display: block;
      cursor: pointer;
      .car-name {
        position: relative;
        margin-top: 100px;
        margin-left: 20%;
        height: 30px;
        width: 77px;
        border: 1px solid #d0d5de;
        border-radius: 15px;
        background-color: #ffffff;

        h6 {
          position: relative;
          margin: 6%;
          padding: 0;
          color: #444c66;
          font-family: "DM Sans";
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0;
          text-align: center;
        }
      }
    }

    label:before {
      background-color: white;
      color: white;
      display: block;
      border-radius: 50%;
      position: absolute;
      top: 10px;
      left: 10px;
      width: 17.99px;
      height: 17.99px;
      text-align: center;
      transition-duration: 0.4s;
      transform: scale(0);
    }

    label img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transition-duration: 0.2s;
      transform-origin: 50% 50%;
    }
    label:focus {
      outline: none;
      background-color: #f6f9ff;
    }

    :checked + label {
      border: 1px solid #474f60;
      border-radius: 4px;
      background-color: #f6f9ff;
      .car-name {
        border: 1px solid #474f60;
        background-color: #474f60;
        h6 {
          color: #ffffff;
        }
      }
    }
    :focus + label:before {
      border: 1px solid #474f60;
      border-radius: 4px;
      background-color: #f6f9ff;
      .car-name {
        border: 1px solid #474f60;
        background-color: #474f60;
        h6 {
          color: #ffffff;
        }
      }
      outline: none;
    }

    :checked + label:before {
      content: "✓";
      background-color: #474f60;
      transform: scale(1);
      -ms-transform: scaleX(1) rotate(15deg); /* IE 9 */
      -webkit-transform: scaleX(1) rotate(15deg); /* Chrome, Safari, Opera */
      transform: scaleX(1) rotate(15deg);
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
      -webkit-font-smoothing: antialiased;
    }

    :checked + label img {
      transform: scale(0.9);
      z-index: 1;
    }
  }
  .car-img {
    box-sizing: border-box;
    position: relative;
    height: 120px;
    width: 100%;
    border: 1px solid #d0d5de;
    border-radius: 4px;
    background-color: #ffffff;

    .check-box {
      display: none !important;
    }
    .input[type="checkbox"][id^="myCheckbox"] {
      display: none !important;
    }
  }
}

.car-type-section {
  box-sizing: border-box;
  height: 50px;
  width: 45.89%;
  border: 1px solid #d0d5de;
  border-radius: 5px 5px 5px 5px;
  background-color: #ffffff;
  display: flex;
  margin-left: 2.91%;
  button {
    height: 48px;
    width: 33.33%;
    font-family: "DM Sans";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    margin-left: 0%;
    box-sizing: border-box;
    cursor: pointer;
  }
  button:focus {
    outline: none;
    opacity: 0.6;
  }

  .button-inactive {
    color: #444c66;
    background-color: #ffffff;
    border: none;
    border-radius: 5px 0 0 5px;
  }
  .button-mid-inactive {
    color: #444c66;
    background-color: #ffffff;
    border: none;
    border-right: 1px solid #d0d5de;
    border-left: 1px solid #d0d5de;
    border-radius: 0px 0 0 0px;
  }
  .button-last-inactive {
    color: #444c66;
    background-color: #ffffff;
    border: none;
    border-radius: 0px 5px 0 5px;
  }
  .button-mid-active {
    border: 1px solid #474f60;
    border-radius: 0px 0px 0px 0px;
    background-color: #474f60;
    color: #ffffff;
  }
  .button-last-active {
    border: 1px solid #474f60;
    border-radius: 0px 5px 5px 0px;
    background-color: #474f60;
    color: #ffffff;
  }

  button:focus {
    outline: none;
  }
  .button-active {
    border: 1px solid #474f60;
    border-radius: 5px 0 0 5px;
    background-color: #474f60;
    color: #ffffff;
  }
}

.MuiSelect-iconOutlined {
  border: none !important;
}
.MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.MuiSelect-icon {
  border: none !important;
}
.MuiSelect-nativeInput {
  outline: none;
  border-bottom: none !important;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}
.MuiSelect-icon {
  top: calc(29% - 12px) !important;
  color: rgba(0, 0, 0, 0.54);
  right: 0;
  font-size: 2.5em !important;
  position: absolute;
  pointer-events: none;
}

.MuiInputBase-input {
  color: #444c66 !important;
  font-family: "DM Sans" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  letter-spacing: 0;
  line-height: 21px;
  padding: 6px 0 7px;
  background: none;
  text-align: left !important;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

.MuiMenu-paper {
  border: 1px solid #f6f9ff !important;
  color: #444c66 !important;
  font-family: "DM Sans" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;
  line-height: 21px !important;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05) !important;
}

.MuiMenuItem-root {
  width: auto;
  overflow: hidden;
  font-size: 16px !important;
  box-sizing: border-box;
  min-height: 48px;
  font-family: "DM Sans" !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  padding-top: 6px;
  white-space: nowrap;
  letter-spacing: 0.00938em;
  padding-bottom: 6px;
}

@media only screen and (max-width: 575px) {
  .inputbox-error {
    font-size: 10px;
  }
  .hm-header {
    padding-left: 2%;
  }
  .hm-header {
    h4 {
      font-size: 20px;
      letter-spacing: 0;
      line-height: 26px;
      padding-right: 10%;
    }
    .com-sch {
      font-size: 11px;
      line-height: 14px;
    }
  }
  .hm-header .in-case-you-dont-ke {
    line-height: 18px;
    font-size: 12px;
  }
  .hm-header p {
    width: 90.36%;
    font-size: 12px;
    line-height: 18px;
  }
  .hm-header .return-trip {
    margin-top: 3%;
    color: #384865;
    font-family: "DM Sans";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }
  .search-home .search-location-input {
    width: 90.44%;
    height: 40px;
  }

  .week-slider {
    width: 270px;
  }
  .slid-val {
    font-size: 14px;
  }
  .week-droop {
    flex-wrap: wrap;
    padding-left: 0;
  }
  .week-droop p {
    margin-top: -1.1%;
    font-size: 12px;
  }

  .image-container {
    flex-wrap: wrap;
  }
  .image-container .car-img-section {
    width: 86px;
    margin-bottom: 7.9%;
  }
  .image-container .car-img {
    height: 70px;
  }
  .image-container .car-img-section label {
    height: 70px;
  }

  .image-container .car-img-section label img {
    max-width: 72px;
  }
  .image-container .car-img-section label .car-name {
    margin-top: 54px;
    margin-left: 16%;
    height: 20px;
    width: 56px;
  }
  .image-container .car-img-section label .car-name h6 {
    font-size: 10px;
    line-height: 12px;
  }
  .car-type-section {
    width: 260px;
    margin-top: 3%;
  }
  .btn-col-02 {
    margin-top: 4.91% !important;
  }
  .sarc-btn{
    width:180px
  }
  
  .image-container .car-img-section :checked + label:before {
    content: "✓";
    font-size: 10px;
  }

  .image-container .car-img-section label:before {
    width: 10.99px;
    height: 10.99px;
  }

  .hm-header .would-you-consider-a {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 21px;
  }

  .hm-header .plug-in-hybrids-phe {
    font-size: 12px;
    line-height: 16px;
  }
  .hm-header .by-clicking-find-my {
    height: 16px;
    font-size: 12px;
    line-height: 16px;
  }

  .hm-header .you-have-somewhere-w {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: 20.91%;
  }
  .rectangle-button {
    width: 65.89%;
    height: 37px;
  }
  .rectangle-button button {
    font-size: 12px;
    line-height: 16px;
    height: 35px;
  }

  .car-type-section button {
    height: 48px;
    width: 33.33%;
    font-size: 11px;
    line-height: 14px;
  }
  .hm-header .plug-in-hybrids-phe {
    margin-top: 9%;
    margin-bottom: 17%;
  }
  .hm-header .by-clicking-find-my {
    font-size: 10px;
    padding-bottom: 33.6%;
  }
  .MuiSelect-selectMenu {
    padding: 0px !important;
    font-size: 12px !important;
  }
  .MuiSelect-icon {
    top: calc(29% - 18px) !important;
  }

  .image-container .car-img-section :checked + label:before {
    font-size: 10px;
  }

  .image-container .car-img-section label:before {
    top: 7px;
    left: 7px;
    width: 12.99px;
    height: 12.99px;
  }
}

@media only screen and (min-width: 576px) {
  .inputbox-error {
    font-size: 12px;
  }
  .hm-header {
    padding-left: 2%;
  }
  .hm-header {
    h4 {
      font-size: 24px;
      letter-spacing: 0;
      line-height: 31px;
      height: 31px;
    }
  }
  .hm-header .in-case-you-dont-ke {
    line-height: 18px;
    font-size: 14px;
  }
  .hm-header p {
    width: 441px;
    font-size: 14px;
    line-height: 18px;
  }
  .hm-header {
    .use-the-slider {
      width: 447px;
    }
  }
  .search-home .search-location-input {
    width: 60.44%;
  }

  .week-slider {
    width: 387px;
  }
  .week-droop p {
    margin-top: 5.1%;
    font-size: 12px;
  }

  .rectangle-button {
    width: 39.89%;
  }
  .image-container {
    flex-wrap: wrap;
  }
  .image-container .car-img-section {
    width: 132px;
    margin-bottom: 5.9%;
  }
  .image-container .car-img {
    height: 100px;
  }
  .image-container .car-img-section label {
    height: 100px;
  }

  .image-container .car-img-section label img {
    max-width: 97px;
  }
  .image-container .car-img-section label .car-name {
    margin-top: 84px;
    margin-left: 22%;
    height: 25px;
    width: 70px;
  }
  .image-container .car-img-section label .car-name h6 {
    font-size: 12px;
  }
  .car-type-section {
    width: 55.89%;
    margin-top: 3%;
  }
  .btn-col-02 {
    margin-top: 4.91% !important;
  }

  .hm-header .would-you-consider-a {
    height: 26px;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 26px;
  }

  .hm-header .plug-in-hybrids-phe {
    height: 60px;
    font-size: 14px;
    line-height: 20px;
  }
  .hm-header .by-clicking-find-my {
    height: 16px;
    font-size: 12px;
    line-height: 16px;
  }

  .hm-header .you-have-somewhere-w {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
  }
  .rectangle-button button {
    font-size: 12px;
    line-height: 16px;
  }

  .car-type-section button {
    height: 48px;
    width: 33.33%;
    font-size: 12px;
    line-height: 18px;
  }
  .hm-header .plug-in-hybrids-phe {
    margin-bottom: 7%;
  }
  .hm-header .by-clicking-find-my {
    font-size: 14px;
    padding-bottom: 22.6%;
  }
  .image-container .car-img-section :checked + label:before {
    font-size: 12px;
  }
  .image-container .car-img-section label:before {
    top: 8px;
    left: 8px;
    width: 14.99px;
    height: 14.99px;
  }
}

@media only screen and (min-width: 768px) {
  .inputbox-error {
    font-size: 14px;
  }
  .hm-header {
    padding-left: 2%;
  }
  .hm-header {
    h4 {
      font-size: 28px;
      letter-spacing: 0;
      line-height: 37px;
      height: 37px;
    }
  }
  .hm-header .in-case-you-dont-ke {
    line-height: 21px;
    font-size: 16px;
  }
  .hm-header p {
    width: 558px;
    font-size: 16px;
    line-height: 21px;
  }
  .hm-header .com-sch {
    font-size: 14px;
    line-height: 18px;
  }
  .hm-header {
    .use-the-slider {
      width: 550px;
    }
  }
  .search-home .search-location-input {
    width: 63.44%;
  }

  .week-slider {
    width: 419px;
  }
  .week-droop p {
    margin-top: 3.6%;
    font-size: 12px;
  }

  .rectangle-button {
    width: 39.89%;
  }
  .image-container .car-img-section {
    width: 115px;
  }
  .image-container .car-img {
    height: 100px;
  }
  .image-container .car-img-section label {
    height: 100px;
  }

  .image-container .car-img-section label img {
    max-width: 97px;
  }
  .image-container .car-img-section label .car-name {
    position: relative;
    margin-top: 84px;
    margin-left: 17%;
    height: 25px;
    width: 70px;
  }
  .image-container .car-img-section label .car-name h6 {
    font-size: 14px;
  }
  .car-type-section {
    width: 55.89%;
  }

  .hm-header .would-you-consider-a {
    height: 26px;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 26px;
  }

  .hm-header .plug-in-hybrids-phe {
    height: 60px;
    font-size: 14px;
    line-height: 20px;
  }
  .hm-header .by-clicking-find-my {
    height: 16px;
    font-size: 12px;
    line-height: 16px;
  }

  .hm-header .you-have-somewhere-w {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 21px;
  }
  .rectangle-button button {
    font-size: 16px;
    line-height: 21px;
  }
  .car-type-section button {
    height: 48px;
    width: 33.33%;
    font-size: 16px;
    line-height: 21px;
  }

  .hm-header .by-clicking-find-my {
    font-size: 12px;
    padding-bottom: 16.6%;
  }
  .card-container
    .upper-container
    .cr-img-02
    .cr-details
    .col-mdl-01
    table
    .square-btn
    img {
    height: 25px;
    width: 20px;
  }
  .image-container .car-img-section :checked + label:before {
    font-size: 12px;
  }

  .image-container .car-img-section label:before {
    top: 8px;
    left: 8px;
    width: 14.99px;
    height: 14.99px;
  }
}

@media only screen and (min-width: 992px) {
  .hm-header {
    padding-left: 1%;
  }

  .hm-header p {
    width: 640px;
    font-size: 18px;
    line-height: 21px;
  }
  .hm-header {
    .use-the-slider {
      width: 575px;
    }
  }
  .hm-header .com-sch {
    font-size: 16px;
    line-height: 21px;
  }
  .hm-header h4 {
    height: 42px;
    font-size: 32px;
  }
  .hm-header .in-case-you-dont-ke {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
  }
  .search-home .search-location-input {
    width: 50.44%;
  }

  .week-slider {
    width: 44.67%;
  }
  .week-droop p {
    margin-top: 2.5%;
    font-size: 14px;
  }
  .rectangle-button {
    width: 29.89%;
  }
  .image-container .car-img-section {
    width: 14.75%;
  }
  .image-container .car-img {
    height: 120px;
  }
  .image-container .car-img-section label {
    height: 120px;
  }
  .image-container .car-img-section label .car-name {
    position: relative;
    margin-top: 100px;
    margin-left: 20%;
    height: 30px;
    width: 77px;
  }
  .image-container .car-img-section label .car-name h6 {
    font-size: 14px;
  }

  .image-container .car-img-section label img {
    max-width: 128px;
  }
  .car-type-section {
    width: 45.89%;
    margin-top: -2%;
  }
  .hm-header .would-you-consider-a {
    height: 31px;
    font-size: 24px;
    line-height: 31px;
  }

  .hm-header .plug-in-hybrids-phe {
    height: 63px;
    font-size: 16px;
    line-height: 21px;
  }
  .hm-header .by-clicking-find-my {
    height: 18px;
    font-size: 14px;
    line-height: 18px;
  }
  .hm-header .you-have-somewhere-w {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px;
  }
  .rectangle-button button {
    font-size: 16px;
    line-height: 21px;
  }

  .car-type-section button {
    height: 48px;
    width: 33.33%;
    font-size: 16px;
    line-height: 21px;
  }
  .hm-header .by-clicking-find-my {
    font-size: 14px;
    padding-bottom: 7%;
    width: 338px;
    z-index: 100;
  }
  .btn-col-02 {
    margin-top: -4.91% !important;
  }

  .image-container .car-img-section :checked + label:before {
    font-size: 14px;
  }
  .image-container .car-img-section label:before {
    top: 9px;
    left: 9px;
    width: 17.99px;
    height: 17.99px;
  }
}
@media only screen and (min-width: 1200px) {
  .hm-header {
    padding-left: 0%;
  }
  .hm-header h4 {
    height: 42px;
    font-size: 32px;
  }
  .hm-header .in-case-you-dont-ke {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
  }
  .hm-header p {
    width: 640px;
    font-size: 18px;
    line-height: 24px;
  }
  .hm-header {
    .use-the-slider {
      width: 575px;
    }
  }
  .search-home .search-location-input {
    width: 480px;
  }

  .week-slider {
    width: 40.17%;
  }
  .week-droop p {
    margin-top: 2.5%;
    font-size: 16px;
  }
  .rectangle-button {
    width: 29.89%;
  }
  .image-container .car-img-section {
    width: 140px;
  }
  .image-container .car-img {
    height: 120px;
  }
  .image-container .car-img-section label {
    height: 120px;
  }
  .image-container .car-img-section label .car-name {
    position: relative;
    margin-top: 100px;
    margin-left: 20%;
    height: 30px;
    width: 77px;
  }
  .image-container .car-img-section label .car-name h6 {
    font-size: 14px;
  }
  .image-container .car-img-section label img {
    max-width: 128px;
  }
  .car-type-section {
    width: 45.89%;
    margin-top: -2%;
  }
  .hm-header .would-you-consider-a {
    height: 31px;
    font-size: 24px;
    line-height: 31px;
  }

  .hm-header .plug-in-hybrids-phe {
    height: 63px;
    font-size: 16px;
    line-height: 21px;
  }

  .hm-header .by-clicking-find-my {
    height: 18px;
    font-size: 14px;
    line-height: 18px;
  }

  .hm-header .you-have-somewhere-w {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px;
  }
  .rectangle-button button {
    font-size: 16px;
    line-height: 21px;
  }

  .car-type-section button {
    height: 48px;
    width: 33.33%;
    font-size: 16px;
    line-height: 21px;
  }

  .hm-header .by-clicking-find-my {
    font-size: 14px;
    padding-bottom: 7%;
    width: 338px;
    z-index: 100;
  }

  .btn-col-02 {
    margin-top: -4.91% !important;
  }

  .image-container .car-img-section :checked + label:before {
    font-size: 14px;
  }
  .image-container .car-img-section label:before {
    top: 10px;
    left: 9px;
    width: 17.99px;
    height: 17.99px;
  }
}
