$transition: 290ms cubic-bezier(0.4, 0, 0.2, 1);

.collapse-css-transition {
  transition: height $transition;
}

a {
  color: black;
  font-size: inherit;
}

.bottom-container {
  position: relative;
  margin-top: -0.5%;
  height: 80px;
  border-radius: 10px;
  padding-top: 1.5%;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background-color: #ffffff;
  box-shadow: -10px -3px 19px 0 rgba(133, 142, 156, 0.07);
  &--inactive {
    transition: 0.3s all ease;
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
  &--active {
    transition: 0.3s all ease;
    opacity: 1;
    height: 80px;
  }

  table {
    width: 100%;
    height: 100%;
    tr {
      text-align: center;
    }
    .mdl-col-2 {
      padding-top: 1%;
      vertical-align: top !important;
    }

    th {
      color: #828796;
      font-family: "DM Sans";
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
      font-weight: 500;
    }
    td {
      vertical-align: text-top;
      color: #444c66;
      font-family: "DM Sans";
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      padding-top: -10%;
    }
  }
}

button {
  font-size: inherit;
  box-shadow: none;
  background: transparent;
}

.sarc-btn{
  width:180px
}

.icon {
  width: 1em;
  height: 1em;
  transition: transform 290ms cubic-bezier(0, 1, 0, 1), fill $transition;
  will-change: transform;
  fill: #444c66;
  &--expanded {
    width: 1em;
    height: 1em;
    fill: #444c66;
    transition: transform 290ms cubic-bezier(0, 1, 0, 1), fill $transition;
    will-change: transform;
    transform: rotateZ(-179.99deg);
  }
}

.app {
  margin: 2% 2% auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;

  &__title {
    margin-top: 0;
    font-size: inherit;
    width: 100%;
    font-weight: normal;
  }

  &__text {
    width: 100%;
    margin: 0 0 1rem;
  }

  &__toggle {
    width: 75%;
    margin: 8% 15% auto;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    transition: background-color 300ms;
    outline: none;
    cursor: pointer;
    &--active {
      cursor: pointer;
      background-color: #fafafa;
      width: 65%;
      margin: 8% 15% auto;
      border: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      transition: background-color 300ms;
      outline: none;
    }
    &-text {
      color: #444c66;
      font-family: "DM Sans";
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
    }
    &-text:hover {
      opacity: 0.7;
    }
    &:focus {
      .icon {
        fill: #444c66;
      }
    }
  }
}

.rotate90 {
  transform: rotateZ(90deg);
}

@media screen and (min-width: 576px) {
  .bottom-container {
    table {
      padding-left: 1%;
      width: 100%;
      tr {
        text-align: left;
      }
    }
  }
  .app__toggle-text {
    font-size: 14px;
    line-height: 18px;
  }
  .app__toggle {
    width: 75%;
    margin: 14% 15% auto;
  }

  .app__toggle--active {
    width: 75%;
    margin: 6% 25% auto;
  }

  .bottom-container table th {
    font-size: 10px;
    line-height: 16px;
  }

  .MuiRating-sizeLarge {
    font-size: 1.275rem !important;
  }
}

@media screen and (max-width: 767px) {
  .bottom-container {
    table {
      padding-left: 4%;
      width: 100%;
      tr {
        text-align: left;
      }
    }
  }
  .bottom-container table td {
    vertical-align: text-top;
    color: #444c66;
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    padding-top: -10%;
  }
  .MuiRating-sizeSmall {
    font-size: 14px !important;
    .MuiSvgIcon-fontSizeInherit {
      font-size: inherit;
      border: none !important;
      height: 20px !important;
      width: 21px !important;
    }
  }
  .MuiSvgIcon-fontSizeSmall {
    font-size: 1.25rem;
    border: none !important;
  }
  .bottom-container--active {
    height: 75px;
  }
}

@media screen and (max-width: 575px) {
  .bottom-container {
    table {
      padding-left: 1%;
      width: 100%;
      tr {
        text-align: left;
      }
    }
  }
  .app__toggle-text {
    font-size: 14px;
    line-height: 18px;
  }

  .app__toggle {
    width: 75%;
    margin: 14% 15% auto;
  }

  .app__toggle--active {
    width: 75%;
    margin: 6% 25% auto;
  }

  .bottom-container table th {
    font-size: 10px;
    line-height: 16px;
  }
  .bottom-container table {
    height: auto;
    margin-left: 2.5%;
  }
  .MuiRating-sizeLarge {
    font-size: 1.275rem !important;
  }

  .bottom-container--active {
    height: 110px;
  }
  .cd-cont-01 .cr-img-header p {
    font-size: 16px;
    line-height: 21px;
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media screen and (min-width: 768px) {
  .bottom-container {
    table {
      padding-left: 1%;
      width: 100%;
      tr {
        text-align: left;
      }
    }
  }
  .app__toggle-text {
    font-size: 14px;
    line-height: 18px;
  }
  .app__toggle {
    width: 75%;
    margin: 14% 15% auto;
  }

  .app__toggle--active {
    width: 75%;
    margin: 14% 15% auto;
  }

  .bottom-container table th {
    font-size: 12px;
    line-height: 16px;
  }
  .MuiRating-sizeLarge {
    font-size: 1.275rem !important;
  }
}

@media screen and (min-width: 992px) {
  .bottom-container table {
    width: 90%;
    height: 100%;
    padding-left: 10%;
    tr {
      text-align: left;
    }
  }
  .app__toggle-text {
    font-size: 16px;
    line-height: 18px;
  }

  .app__toggle {
    width: 65%;
    margin: 8% 15% auto;
  }

  .app__toggle--active {
    width: 65%;
    margin: 8% 15% auto;
  }

  .bottom-container table th {
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (min-width: 1200px) {
  .bottom-container {
    table {
      padding-left: 1%;
      width: 100%;
      tr {
        text-align: left;
      }
    }
  }

  .app__toggle-text {
    font-size: 16px;
    line-height: 18px;
  }

  .app__toggle {
    width: 112px;
    margin: 8% 15% auto;
  }

  .app__toggle--active {
    width: 112px;
    margin: 8% 15% auto;
  }

  .bottom-container table th {
    font-size: 14px;
    line-height: 18px;
  }
}
