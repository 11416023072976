.row-1-panel {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sarc-btn{
  width:180px
} 

.header-container {
  height: 77px;
  width: 100%;
  flex: 0 1 auto;
  position: relative;
  background-color: #000000;

  > div {
    height: 77px;
  }

  img {
    margin: 20px 0;
    height: 34px;
    width: 128px;
  }

  .progress-bar-header {
    width: 100%;
    position: absolute;
    bottom: 0px;
    background-color: rgba(136, 139, 149, 0.5);
    height: 7px;
    overflow: hidden;
    .filler {
      position: absolute;
      height: 7px;
      background-color: #2c91ea;
    }
  }
}

.panel-container {
  position: relative;
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  background-color: #f5f7f9;
}
.left-panel {
  position: relative;
  min-height: calc(100vh - 77px);
  width: 27.03%;
  max-width: 340px;
  background-color: #221f1f;
  box-shadow: 10px 0 30px 0 rgba(0, 0, 0, 0.05);
  text-align: left;
  .logo-section {
    padding: 10.91% 3.13% 4.13% 9.13%;
    width: 100%;
    img {
      height: 34px;
      width: 128px;
    }
  }
  .cont-us {
    position: absolute;
    bottom: 0;
    width: 25%;
    padding-left: 35px;
    padding-bottom: 5.59%;
    button {
      cursor: pointer;
      height: 35px;
      width: 97px;
      border-radius: 5px;
      background-color: #161313;
      color: #ffffff;
      font-family: "DM Sans";
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
      border: none;
      outline: none;
    }
    @media not all and (min-resolution:.001dpcm)
    { @supports (-webkit-appearance:none) {
      button {
        width:110px;
      }
      
    }
  }
    button:focus {
      outline: none;
      border: 1px solid #858e9c;
      border-radius: 5px;
      background-color: #353535;
    }
    button:hover {
      border: 1px solid #858e9c;
      border-radius: 5px;
      background-color: #353535;
    }
  }
}
.right-panel {
  position: relative;
  padding-top: 0%;
  background-color: #f5f7f9;
  overflow: auto;
  flex: 1;
  .upper-panel {
    position: relative;
    z-index: 90;
    width: 99.22%;
    max-width: 967px;
    min-height: 51.27%;
    margin-top: 1.88%;
    margin-left: 1.88%;
    margin-right: 1.88%;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
    .progress-bar {
      width: 100%;
      position: relative;
      top: 0;
      background-color: lightgrey;
      height: 8px;
      border-top-left-radius: 35px;
      border-top-right-radius: 35px;
      overflow: hidden;
      .filler {
        position: absolute;
        height: 8px;
        background-color: #2c91ea;
      }
    }
    .step-out-of-6 {
      width: 100%;
      text-align: left;
      p {
        margin: 3.13%;

        color: rgba(136, 139, 149, 0.5);
        font-family: "DM Sans";
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
      }
    }
    .button-container {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
    }
    .nex-button {
      margin: 0 2% 4% 0;
      height: 50px;
      width: 17.28%;
      border-radius: 5px;
      background-color: #2c91ea;
      border: none;
      color: #ffffff;
      box-shadow: 3px 10px 28px 0 rgba(74, 104, 130, 0.4);
      font-family: "DM Sans";
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 21px;
      text-align: center;
      cursor: pointer;
    }
    .nex-button:focus {
      outline: none;
      background-color: #3abaff;
    }
    .nex-button:hover {
      background-color: #3abaff;
    }
    .disabled-button {
      background-color: #d0d5de;
      color: #ffffff;
      margin: 0 2% 4% 0;
      height: 50px;
      width: 17.28%;
      border-radius: 5px;
      border: none;
      color: #ffffff;
      font-family: "DM Sans";
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 21px;
      text-align: center;
    }
    .disabled-button:focus {
      outline: none;
    }
    .back-button {
      box-sizing: border-box;
      margin: 0 2% 4% 50%;
      height: 50px;
      width: 17.28%;
      border: 1px solid #d0d5de;
      border-radius: 5px;
      background-color: #ffffff;
      color: #828796;
      font-family: "DM Sans";
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
      cursor: pointer;
    }
    .back-button:focus {
      outline: none;
      border: 1px solid #474f60;
      color: #474f60;
    }
    .back-button:hover {
      border: 1px solid #474f60;
      color: #474f60;
    }
  }
}
.right-panel > div {
  width: 97%;
  position: absolute;
  min-height: 100%;
}

.wait-circle {
  position: absolute;
  width: 99.22%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .finding-your-ev-matc {
    color: #444c66;
    font-family: "DM Sans";
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 31px;
    text-align: center;
  }
  .MuiCircularProgress-colorPrimary {
    color: #2c91ea !important;
  }
}

@media screen and (max-width: 575px) {
  .header-container {
    height: 65px;
  }
  .right-panel {
    .upper-panel {
      min-height: 400px;
    }
  }
  .right-panel > div {
    width: 100%;
  }
  .left-panel {
    width: 0px;
    > div {
      height: 100%;
    }
  }
  .left-panel .cont-us {
    position: absolute;
    bottom: 100px;
    padding-left: 15px;
  }
  .progress-bar {
    opacity: 0;
  }
  .left-panel .logo-section {
    display: none;
  }

  .right-panel .upper-panel .back-button {
    width: 44.28%;
    height: 35px;
    font-size: 14px;
    margin: 0 2% 4% 1%;
  }
  .right-panel .upper-panel .disabled-button {
    width: 44.28%;
    height: 35px;
    font-size: 14px;
  }

  .right-panel .upper-panel .nex-button {
    width: 44.28%;
    height: 35px;
    font-size: 14px;
  }
  .right-panel .upper-panel .step-out-of-6 p {
    margin-left: 4.83%;
  }
  .right-panel .upper-panel {
    width: auto;
    margin-top: 4.88%;
    margin-left: 4.88%;
    margin-right: 4.88%;
  }

  .header-container .progress-bar-header {
  }
  .header-container img {
    margin: 17px 0;
    height: 28px;
    width: 106px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (min-width: 576px) {
  .right-panel {
    .upper-panel {
      min-height: 500px;
    }
  }
  .right-panel > div {
    width: 100%;
  }
  .left-panel {
    width: 0px;
    > div {
      height: 100%;
    }
  }
  .left-panel .logo-section {
    display: none;
  }

  .left-panel .cont-us {
    position: absolute;
    bottom: 100px;
  }
  .progress-bar {
    opacity: 0;
  }

  .right-panel .upper-panel .back-button {
    width: 115px;
    height: 35px;
    font-size: 14px;
  }
  .right-panel .upper-panel .disabled-button {
    width: 115px;
    height: 35px;
    font-size: 14px;
  }

  .right-panel .upper-panel .nex-button {
    width: 115px;
    height: 35px;
    font-size: 14px;
  }
  .right-panel .upper-panel .step-out-of-6 p {
    margin-left: 4.83%;
  }
  .right-panel .upper-panel {
    width: auto;
    margin-top: 4.88%;
    margin-left: 4.88%;
    margin-right: 4.88%;
  }

  .header-container .progress-bar-header {
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media screen and (min-width: 768px) {
  .right-panel {
    .upper-panel {
      min-height: 510px;
    }
  }
  .right-panel > div {
    width: 100%;
  }
  .left-panel {
    width: 0px;
    > div {
      height: 100%;
    }
  }
  .left-panel .cont-us {
    position: absolute;
    bottom: 100px;
  }
  .left-panel .logo-section {
    display: none;
  }

  .progress-bar {
    opacity: 0;
  }

  .right-panel .upper-panel .back-button {
    width: 150px;
    height: 40px;
    font-size: 16px;
  }
  .right-panel .upper-panel .disabled-button {
    width: 150px;
    height: 40px;
    font-size: 16px;
  }

  .right-panel .upper-panel .nex-button {
    width: 150px;
    height: 40px;
    font-size: 16px;
  }
  .right-panel .upper-panel .step-out-of-6 p {
    margin-left: 4.83%;
  }
  .right-panel .upper-panel {
    width: auto;
    margin-top: 4.88%;
    margin-left: 4.88%;
    margin-right: 4.88%;
  }

  .header-container .progress-bar-header {
  }
}

/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .right-panel {
    .upper-panel {
      min-height: 550px;
    }
  }
  .right-panel > div {
    width: 100%;
  }
  .left-panel {
    width: 0px;
    > div {
      height: 100%;
    }
  }
  .left-panel .cont-us {
    position: absolute;
    bottom: 100px;
  }
  .left-panel .logo-section {
    display: none;
  }

  .progress-bar {
    opacity: 0;
  }

  .right-panel .upper-panel .back-button {
    width: 170px;
    height: 50px;
    font-size: 18px;
  }
  .right-panel .upper-panel .disabled-button {
    width: 170px;
    height: 50px;
    font-size: 18px;
  }

  .right-panel .upper-panel .nex-button {
    width: 170px;
    height: 50px;
    font-size: 18px;
  }
  .right-panel .upper-panel .step-out-of-6 p {
    margin-left: 3.8%;
  }
  .right-panel .upper-panel {
    width: auto;
    margin-top: 2.88%;
    margin-left: 2.88%;
    margin-right: 2.88%;
  }
  .header-container .progress-bar-header {
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .right-panel {
    > div {
      width: 98%;
    }
    .upper-panel {
      min-height: 550px;
    }
  }
  .left-panel {
    width: 27.03%;
    min-height: 100vh;
    > div {
      height: 97%;
    }
  }
  .left-panel .cont-us {
    position: fixed;
    top: 90%;
    padding-left: 2%;
  }

  .header-container {
    height: 0px;
  }
  .left-panel .logo-section {
    display: block;
  }
  .progress-bar {
    opacity: 1;
  }

  .right-panel .upper-panel .back-button {
    width: 170px;
    height: 50px;
    font-size: 18px;
  }
  .right-panel .upper-panel .disabled-button {
    width: 170px;
    height: 50px;
    font-size: 18px;
  }
  .right-panel .upper-panel .nex-button {
    width: 170px;
    height: 50px;
    font-size: 18px;
  }
  .right-panel .upper-panel .step-out-of-6 p {
    margin-left: 3.13%;
  }
  .right-panel .upper-panel {
    margin-top: 1.88%;
    margin-left: 1.88%;
    margin-right: 1.88%;
  }
  .header-container .progress-bar-header {
  }
}
