.map-container {
  width: 100%;
  height: calc(100vh + 660px) !important;
}

.gm-fullscreen-control {
  display: none;
}

@media only screen and (max-width: 767px) {
  .map-container {
    width: 100%;
    height: calc(100vh + 500px) !important;
    vertical-align: bottom;
  }
}

@media only screen and (max-width: 575px) {
  .map-container {
    width: 100%;
    height: calc(100vh + 400px) !important;
    vertical-align: bottom;
  }

  .sarc-btn{
    width:180px;
  }
}
