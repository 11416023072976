.terms-container{
    margin:0;
    padding:7% 13%;
    text-align: left;
    line-height: 30px;
    color:black;
    font-family: "DM Sans";
    letter-spacing: 0;
    p{
        color:black;
        font-weight: 500;
        font-size: 18px;
    }

}