.main-container {
  padding: 0;
  margin: 0;
  position: relative;
  text-align: center;
  min-height: 1024px;
  width: 100%;
  background-color: #f5f7f9;
}

.screen {
  position: absolute;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .scr-content {
    margin-top: 18%;

    h3 {
      color: #ffffff;
      font-family: "DM Sans";
      font-size: 48px;
      font-weight: 700;
      letter-spacing: -0.8px;
      line-height: 63px;
      text-align: center;
    }
    p {
      margin-top: -2%;
      color: #ffffff;
      font-family: "DM Sans";
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 30px;
      text-align: center;
    }
  }
}

.search-rectangle {
  position: relative;
  margin-top: 5%;
  width: 50%;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
  padding: 0.81% 3.13% 3.81%;
  p {
    color: #778db1;
    font-family: "DM Sans";
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 31px;
    text-align: left;
  }
  .sch-col-1 {
    display: flex;
    width: 100%;
    position: relative;
    margin-top: -1.6%;

    button {
      width: 28%;
      border-radius: 0 10px 10px 0;
      outline: none;
      border: none;
      font-family: "DM Sans";
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
    }
    .active-button {
      box-shadow: 0 5px 10px 0 rgba(44, 145, 234, 0.14);
      background-color: #2c91ea;
      color: #ffffff;
      cursor: pointer;
    }
    .active-button:hover {
      background-color: #3abaff;
    }
    .active-button:focus {
      background-color: #3abaff;
    }
    .disabled-button {
      background-color: #d0d5de;
      color: #ffffff;
    }
  }

  .search-location-input {
    position: relative;
    display: flex;
    box-sizing: border-box;
    height: 65px;
    width: 63.83%;
    border: 1px solid #c3cfe2;
    border-radius: 10px 0 0 10px;
    background-color: #ffffff;
    box-sizing: border-box;
    cursor: pointer;
  }

  .search-location-input:focus-within {
    border: 1px solid #c3cfe2;
    border-radius: 10px 0 0 10px;
    border-color: rgba(223, 225, 229, 0);
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  }

  .search-location-input:hover {
    border: 1px solid #c3cfe2;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
    border-color: rgba(223, 225, 229, 0);
  }

  .search-location-input input {
    border: none;
    width: 100%;
    background-color: transparent;
    color: "black";
    font-family: "DM Sans";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    padding-left: 8%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .search-location-input input:focus {
    outline: none;
  }
  .loc-img {
    position: absolute;
    top: 28%;
    left: 2%;
    height: 25px;
    width: 17px;
  }
}

@media only screen and (max-width: 575px) {
  .main-container {
    min-height: 640px;
  }
  .screen {
    height: 70%;
  }
  .search-rectangle {
    width: 80%;
    margin-top: 10%;
    padding: 0.81% 0% 1% 3.13%;
    p {
      font-size: 16px;
      line-height: 21px;
    }
  }
  .search-rectangle .sch-col-1 {
    flex-wrap: wrap;
    margin-bottom: 7%;
  }
  .search-rectangle .search-location-input {
    height: 40px;
    width: 95.83%;
    border-radius: 3px;
  }
  @media not all and (min-resolution:.001dpcm)
  { @supports (-webkit-appearance:none) {
  
    
    .search-rectangle .search-location-input input {
      font-size: 16px !important;
      
    }
    .search-rectangle .search-location-input input:focus {
      font-size: 16px !important;
      
    }
  }
}
  .search-rectangle .search-location-input input {
    font-size: 12px;
    padding-left: 12%;
    border-radius: 3px;
    height: 40px;
  }
  
  .search-rectangle .loc-img {
    height: 18px;
    width: 14px;
  }
  .search-rectangle .sch-col-1 button {
    margin-top: 3%;
    margin-bottom: 4%;
    width: 95.83%;
    height: 40px;
    border-radius: 3px;
    font-size: 16px;
  }
  .screen {
    height: 60%;
    background-size: cover;
    .scr-content {
      margin-top: 48%;
      margin-left: 4%;
      img {
        width: 128px;
        height: 34px;
      }

      h3 {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: -0.47px;
        line-height: 37px;
        text-align: center;
      }
      p {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 30px;
        text-align: center;
        padding-left: 5%;
        padding-right: 5%;
      }
    }
  }
 
}

@media only screen and (min-width: 576px) {
  .main-container {
    min-height: 700px;
  }
  .screen {
    height: auto;
  }
  .search-rectangle {
    width: 470px;
    margin-top: 10%;
    padding: 0.81% 0% 5.81% 3.13%;
    p {
      font-size: 18px;
      line-height: 21px;
    }
    .sch-col-1 button {
      width: 23%;
    }
  }
  .search-rectangle .search-location-input {
    height: 50px;
    width: 73.83%;
  }
  .search-rectangle .loc-img {
    position: absolute;
    top: 28%;
    left: 2%;
    height: 18px;
    width: 14px;
  }
  .search-rectangle .search-location-input input {
    font-size: 14px;
    padding-left: 10%;
  }
  .screen {
    background-size: cover;
    .scr-content {
      margin-top: 22%;
      margin-left: 1%;

      h3 {
        font-size: 28px;
        font-weight: 700;
        letter-spacing: -0.47px;
        line-height: 37px;
        text-align: center;
      }
      p {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 30px;
        text-align: center;
        padding-left: 10%;
        padding-right: 13%;
      }
    }
  }
}
@media only screen and (min-width: 768px) {
  .main-container {
    min-height: 700px;
  }
  .screen {
    height: auto;
  }
  .search-rectangle {
    width: 520px;
    margin-top: 10%;
    padding: 0.81% 3.13% 3.81%;
    p {
      font-size: 20px;
      line-height: 26px;
    }
  }
  .search-rectangle .search-location-input {
    height: 65px;
    width: 73.83%;
  }
  .search-rectangle .loc-img {
    position: absolute;
    top: 28%;
    left: 2%;
    height: 25px;
    width: 17px;
  }
  .search-rectangle .search-location-input input {
    padding-left: 10%;
    color: "black";
    font-family: "DM Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 21px;
  }
  .search-rectangle .sch-col-1 button {
    width: 29%;
  }
  .screen {
    background-size: 100% 100%;
    .scr-content {
      margin-top: 16%;
      margin-left: 1%;
      img {
      }
      h3 {
        font-size: 36px;
        font-weight: 700;
        letter-spacing: -0.6px;
        line-height: 47px;
        text-align: center;
      }
      p {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 30px;
        text-align: left;
        padding-left: 0%;
        padding-right: 0%;
        text-align: center;
      }
    }
  }
}
@media only screen and (min-width: 992px) {
  .main-container {
    min-height: 700px;
  }
  .screen {
    height: auto;
  }
  .search-rectangle {
    width: 650px;
    margin-top: 5%;
    padding: 0.81% 3.13% 3.81%;
    p {
      font-size: 22px;
      line-height: 31px;
    }
  }
  .search-rectangle .search-location-input {
    height: 65px;
    width: 73.83%;
  }
  .search-rectangle .sch-col-1 button {
    width: 29%;
  }
  .search-rectangle .search-location-input input {
    padding-left: 8%;
    color: "black";
    font-family: "DM Sans";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
  }
  .screen {
    background-size: 100% 100%;
    .scr-content {
      margin-top: 14%;
      margin-left: 0;

      h3 {
        font-size: 44px;
        font-weight: 700;
        letter-spacing: -0.8px;
        line-height: 63px;
      }
      p {
        margin-top: -2%;
        font-size: 22px;
        line-height: 30px;
        padding-left: 0%;
        padding-right: 0%;
        text-align: center;
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  .main-container {
    min-height: 800px;
  }
  .screen {
    height: auto;
  }

  .search-rectangle {
    width: 680px;
    margin-top: 5%;

    padding: 0.81% 3.13% 3.81%;
    p {
      font-size: 24px;
      line-height: 31px;
    }
  }
  .search-rectangle .search-location-input {
    height: 65px;
    width: 497px;
  }
  .search-rectangle .search-location-input input {
    padding-left: 8%;
    color: "black";
    font-family: "DM Sans";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
  }
  .search-rectangle .sch-col-1 button {
    width: 180px;
  }
  .screen {
    background-size: 100% 100%;
  }
  .screen .scr-content {
    margin-top: 14%;
    margin-left: 0;

    h3 {
      font-size: 48px;
      font-weight: 700;
      letter-spacing: -0.8px;
      line-height: 63px;
    }
    p {
      margin-top: -2%;
      font-size: 24px;
      line-height: 30px;
      padding-left: 0%;
      padding-right: 0%;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .screen{
    height:auto;
  }
  
}

@media only screen and (min-width: 1600px) {
  .main-container {
    min-height: 800px;
  }
  .search-rectangle {
    width: 700px;
  }
  .sarc-btn{
    width:180px;
  }
}
