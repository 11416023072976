.MuiSvgIcon-root {
  border-radius: 50%;
  border: 2px solid #828796;
  text-align: center;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 2rem;
  font-weight: 600 !important;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}


.sarc-btn{
  width:180px
}

.stepper-entity {
  margin-left: 17.45%;
  margin-top: 0%;
  padding: 0%;
  height: 21px;
  color: rgba(136, 139, 149, 0.5);
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  display: flex;
  align-items: center;

  .MuiSvgIcon-root {
    border: none !important;
    margin-top: 25%;
    width: 0.5em !important;
    height: 0.5em !important;
    margin-left: 90% !important;
    cursor: pointer !important;
  }
}

.MuiStep-vertical {
  color: white;
}
.MuiStepLabel-iconContainer {
  display: flex;
  flex-shrink: 0;
  padding-right: 8px;
  margin-right: 13px !important;
}

.MuiStepper-root {
  display: flex;
  padding: 24px;
  padding-left: 35px !important;
  background-color: transparent !important;
}

.MuiPaper-root {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.MuiStepIcon-root.MuiStepIcon-active {
  color: white !important;
  border: 2px solid #ffffff !important;
  background-color: white !important;
  .MuiStepIcon-text {
    fill: black !important;
    font-size: 1rem !important;
    font-family: "DM Sans";
  }
}
.MuiStepIcon-root {
  color: #221f1f !important;
  display: block;
  padding-top: 2%;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #828796 !important;
  border: 2px solid transparent !important;
}

.MuiStepLabel-label.MuiStepLabel-active {
  color: #ffffff !important;
}

.MuiStepIcon-text {
  fill: #757a89 !important;
  font-size: 0.9rem !important;
  font-family: "DM Sans";
}

.MuiTypography-body2 {
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
  line-height: 17px !important;
  color: #757a89;
}

.MuiStepLabel-label {
  color: #757a89 !important;
}
@media only screen and (max-width: 320px) {
  .stepper-entity {
    margin-left: 19.45%;
  }
  .MuiStepper-root {
    padding-left: 15px !important;
  }
}

@media only screen and (min-width: 320px) {
  .stepper-entity {
    margin-left: 19.45%;
  }
  .MuiStepper-root {
    padding-left: 15px !important;
  }
}

@media only screen and (min-width: 576px) {
  .stepper-entity {
    margin-left: 19.45%;
  }
  .MuiStepper-root {
    padding-left: 35px !important;
  }
}

@media only screen and (min-width: 768px) {
  .stepper-entity {
    margin-left: 20.45%;
  }
}

@media only screen and (min-width: 992px) {
  .stepper-entity {
    margin-left: 19.45%;
  }
}
@media only screen and (min-width: 1200px) {
  .stepper-entity {
    margin-left: 19.45%;
  }
}
