.cd-cont-01 {
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
  .upper-container {
    position: relative;
    width: 100%;
    height: 85%;
    display: flex;
  }
  .cr-img-header {
    position: relative;
    height: 20%;
    a {
      text-decoration: none;
    }
    a:focus {
      outline: none;
      text-decoration: underline;
    }
    a:hover {
      text-decoration: underline;
    }
    p {
      padding-top: 16px;
      padding-left: 2.78%;
      color: #444c66;
      font-family: "DM Sans";
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 26px;
      text-align: left;
    }
  }
  .col-mdl-01 {
    padding: 2.78%;
    min-width: 48%;
    .col-btn-container {
      width: 100%;
      padding-bottom: 4%;
      margin-top: 8%;
    }
    table {
      width: 100%;
      height: 100%;
      tr {
        text-align: left;
      }
      th,
      td {
        text-align: left;
      }
      th {
        color: #828796;
        font-family: "DM Sans";
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        font-weight: 500;
        max-width: max-content;
        white-space: nowrap;
      }
      td {
        color: #444c66;
        font-family: "DM Sans";
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
        white-space: nowrap;
      }
      .book-test-button {
        height: 35px;
        width: 90%;
        border-radius: 5px;
        border: none;
        background-color: #2c91ea;
        color: #ffffff;
        font-family: "DM Sans";
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px;
        text-align: center;
        outline: none;
      }
      .book-test-button:hover {
        background-color: #3abaff;
      }
      .book-test-button:focus {
        background-color: #3abaff;
      }
      .drive-inactive{
        background-color: #D0D5DE!important;
      }

      .view-car {
        height: 35px;
        width: 90%;
        border: 1px solid #d0d5de;
        border-radius: 5px;
        background-color: #ffffff;
        color: #2c91ea;
        font-family: "DM Sans";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 16px;
        text-align: center;
        cursor: pointer;
      }
      .blue-car:hover {
        border: 1px solid #2c91ea;
      }
      .reser-open {
        color: #2ec1ac !important;
      }
      .reser-open:hover {
        color: #2ec1ac !important;
        border: 1px solid #2ec1ac !important;
      }
      .com-soon {
        color: #ee9022 !important;
      }
      .com-soon:hover {
        border: 1px solid #ee9022 !important;
        color: #ee9022 !important;
      }
      .alloc-sold {
        color: #c65258 !important;
      }
      .alloc-sold:hover {
        border: 1px solid #c65258 !important;
        color: #c65258 !important;
      }
      .square-btn {
        cursor: pointer;
        box-sizing: border-box;
        height: 35px;
        width: 35px;
        border: 1px solid #d0d5de;
        border-radius: 5px;
        background-color: #ffffff;
        letter-spacing: 0;
        text-align: center;
        img {
          height: 15px;
          width: 15px;
        }
      }
      button:focus {
        outline: none;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .card-container .upper-container {
    position: relative;
    width: 100%;
    height: 200px;
    display: flex;
  }
  .cd-cont-01 .col-mdl-01 table .square-btn img {
    height: 20px;
    width: 17px;
    vertical-align: middle;
  }
}

@media only screen and (max-width: 575px) {
  .MuiBox-root {
    margin-bottom: 0px !important;
    padding: 0px !important;
  }
  .cd-cont-01 .col-mdl-01 table .square-btn {
    height: 30px;
  }
  .cd-cont-01 .col-mdl-01 table .square-btn img {
    height: 14px;
    width: 12px;
    vertical-align: middle;
  }
  .cd-cont-01 .col-mdl-01 table .book-test-button {
    font-size: 11px;
    width: 130px;
    height: 30px;
    
  }

  .cd-cont-01 .col-mdl-01 table .view-car {
    font-size: 11px;
    width: 110px;
    height: 30px;
  }
  @media not all and (min-resolution:.001dpcm)
  { @supports (-webkit-appearance:none) {
  
    .cd-cont-01 .col-mdl-01 table .book-test-button {
      font-size: 11px;
      width: 140px;
      height: 30px;
      
    }
    .cd-cont-01 .col-mdl-01 table .view-car {
      font-size: 11px;
      width: 130px;
      height: 30px;
    }
    .cd-cont-01 .col-mdl-01 table .square-btn {
      width:25px;
      display:flex;
      justify-content: center;
      align-items: center;
      
    }
    .cd-cont-01 .col-mdl-01 table .square-btn img {
      height: 14px;
      width: 12px;
      vertical-align: middle;
    }
  }}
  .cd-cont-01 .col-mdl-01 {
    padding: 0% 2.78% 2.78% 2.78%;
    min-width: 48%;
  }
  .cd-cont-01 .col-mdl-01 .col-btn-container {
    width: 100%;
    padding-bottom: 4%;
    margin-top: 0%;
  }
  .cd-cont-01 .col-mdl-01 table th {
    font-size: 11px;
    line-height: 14px;
  }
  .cd-cont-01 .col-mdl-01 table td {
    font-size: 14px;
    line-height: 18px;
  }
  .card-container .upper-container {
    position: relative;
    width: 100%;
    height: 180px;
    display: flex;
  }
  .card-container .upper-container .cr-img a {
    width: 96.75%;
    height: 143px;
  }
   .sarc-btn{
     width:180px
   }
}
