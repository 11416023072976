
#progressInput {
  margin: 20px auto;
  width: 30%;
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
}

.circle-progress {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 3em;
  font-weight: bold;
  fill: #444c66;
  font-family: "DM Sans";
  font-size: 44px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 52px;
  text-align: center;
  margin-top: 4% !important;
}

.sarc-btn{
  width:180px
}